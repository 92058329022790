<template>
  <b-card>
    <!-- Table Top -->
    <b-row>
      <b-col>
        <div class="d-flex align-items-center justify-content-end">
          <div>
            <b-button
              variant="primary"
              :to="{ name: 'champion-resource-categories' }"
              class="mr-1"
            >
              <feather-icon
                icon="ListIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Categories List</span>
            </b-button>
            <b-button
              v-if="hasProgramAdminRole"
              variant="primary"
              class="mr-1"
              @click="onAddResourceClick"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Add Resource</span>
            </b-button>
            <add-resource-to-program
              v-if="hasProgramAdminRole"
              :show-modal="showAddResourcesModal"
              :program="defaultProgram"
              @hiddenModal="hiddenAddResourceModal"
              @resourcesAdded="onCreateResource"
            />
            <!-- Create resource -->
            <action-button
              v-b-toggle.add-resource-right
              variant="primary"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Create Resource</span>
            </action-button>
            <b-sidebar
              id="add-resource-right"
              title="Add Resources"
              bg-variant="white"
              right
              backdrop
              shadow
              style="min-width: 600px !important"
              width="600px"
            >
              <div>
                <add-resource
                  :is-program-resource="true"
                  @resourceAdded="onCreateResource"
                />
              </div>
            </b-sidebar>
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- table -->
    <resources-list
      ref="resourcesList"
      @edit="editResource"
    />

    <b-sidebar
      id="edit-resource-right"
      title="Edit Resource"
      bg-variant="white"
      right
      backdrop
      shadow
      style="min-width: 600px !important"
      width="600px"
    >
      <div>
        <edit-resource
          :is-program-resource="true"
          :resource-id="resourceId"
          @resourceUpdated="onRefreshItems"
        />
      </div>
    </b-sidebar>
  </b-card>
</template>

<script>
import { BButton, BRow, BCol, BCard, BSidebar, VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AddResource from "@/views/components/forms/resources/AddResource.vue";
import EditResource from "@/views/components/forms/resources/EditResource.vue";
import ResourcesList from "./ResourcesList.vue";
import AddResourceToProgram from "../../admin/resources/AddResourceToProgram.vue";
import { mapGetters } from 'vuex';
import ActionButton from "@/views/components/common/ActionButton.vue";

export default {
  components: {
    ActionButton,
    BCard,
    BButton,
    BRow,
    BCol,
    AddResource,
    EditResource,
    ResourcesList,
    BSidebar,
    AddResourceToProgram,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      resourceId: null,
      showAddResourcesModal: false,
    };
  },
  computed: {
    ...mapGetters("profile", ["profile", 'isProgramAdmin']),
    ...mapGetters("programs", ["defaultProgram"]),
    hasProgramAdminRole() {
      return this.isProgramAdmin(Number(this.$route.params.id));
    },
  },
  methods: {
    onRefreshItems() {
      this.$refs.resourcesList.loadItems();
    },
    editResource(id) {
      this.resourceId = id;
    },
    async onCreateResource() {
      this.onRefreshItems();
    },
    onAddResourceClick() {
      this.showAddResourcesModal = true;
    },
    hiddenAddResourceModal(isShow) {
      this.showAddResourcesModal = isShow;
    },
  },
};
</script>
<style lang="scss">
 $sidebar-header-text-color: #808080;
    .b-sidebar > .b-sidebar-header{
      flex-direction: row-reverse;
      background: #f3f3f3;
      color: $sidebar-header-text-color;
  
      #edit-resource-right___title__ {
        font-size: .8em;
        flex: 2;
        text-align: left;
      }
      #add-resource-right___title__ {
        font-size: .8em;
        flex: 2;
        text-align: left;
      }
    }
</style>